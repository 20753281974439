body[data-template="home"] {
  header > nav {
    display: none;
  }

  .main_home {
    & > * + * {
      padding-top: 2em;
      padding-bottom: 1em;
    }
  }

  .hero {
    position: relative;
    padding-top: 2em;
    padding-bottom: 1em;
    min-height: calc(100vh - 1rem);
    border-radius: var(--box-radius);
    overflow: hidden;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background:
        linear-gradient(
          hsl(var(--bg-very-dark-hsl) / 50%) 10%,
          hsl(var(--bg-very-dark-hsl) / 50%) 35%,
          hsl(var(--bg-hsl) / 25%) 75%
        ),
        var(--heroimage) no-repeat center center;
      background-size: cover;
    }
  }

  .herotitle {
    position: relative;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
    line-height: 1.1;
    font-size: clamp(1rem, 9vw, 9.8rem);
    text-align: center;
    color: var(--text-color-white);
  }

  .heronav {
    padding-inline: 10vw;
    margin-bottom: 3em;
    font-size: var(--step-1);

    a {
      color: var(--text-color-white);
    }
  }

  .intro {
    --direction: row;

    position: sticky;
    bottom: 100px;
    min-height: calc(100vh - (var(--header-height) - 2rem));
    margin-inline: auto;
    padding: 2em 1em;
    padding-top: 3em;
    background:
    linear-gradient(
      hsl(var(--bg-very-dark-hsl) / 50%) 10%,
      hsl(var(--bg-very-dark-hsl) / 50%) 35%,
      hsl(var(--bg-hsl) / 25%) 75%
    );

    nav {
      --fluid-20-28: clamp(20px, 10.0561px + 1.2461vi, 28px);
      --gutter: 0.5rem clamp(1em, 5vw, 5em);

      font-size: var(--fluid-20-28);
      font-variation-settings:
        "optz" 50,
        "wght" 400,
        "wdth" 87.5;

      @media (max-width: 600px) {
        display: none;
      }
    }

    .intro-inner {
      margin-top: 16vh;
      margin-inline: auto;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: var(--direction);
      gap: 5vmax;
      align-items: center;
      justify-content: space-evenly;
      text-align: justify;
    }

    .featured {
      flex: 0 3 min(300px, 25vw);
      max-width: 60vw;
      display: grid;

      & > * {
        grid-area: 1 / 1;
      }

      .featured-button {
        pointer-events: none;
        position: relative;
        z-index: 1;
        place-self: center;
        transition: all 0.2s ease-in;
        color: var(--text-color-white);
        opacity: 0;

        &::before {
          content: "";
          width: 10vw;
          height: 10vw;
          border: 1px solid var(--accent-color);
          border-radius: 50%;
          background-color: var(--bg);
          opacity: 0.5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0.8);
          z-index: -1;
        }
      }

      &:hover {
        .featured-button {
          opacity: 1;

          &::before {
            transform: translate(-50%, -50%) scale(1.1);
          }
        }
      }
    }

    .intro-text {
      flex: 2 1 35vw;
      max-width: 48ch;
    }

    .logos {
      display: flex;
      justify-content: end;
      margin-top: 5vw;
    }

    .logo {
      width: max(2rem, 3vw);

      svg {
        width: 100%;
        height: auto;
        color: var(--bg);
      }
    }

    @media (max-width: 768px) {
      --direction: column;

      position: initial;
    }
  }

  .scrolldown {
    --arrow-color: var(--text-color);
    --display: "block";

    position: absolute;
    opacity: 1;
    display: var(--display);
    padding: 0.5rem;
    font-size: var(--step-0);
    font-variation-settings: "wght" 300;
    line-height: 0.9;
    text-align: center;
    color: var(--arrow-color);
    bottom: 1rem;
    left: 0;
    width: 100%;

    /* Not working with gsap:
    animation: bounce-top 1.5s ease-in-out 5 both; */

    &.hidden {
      display: none;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-26 18:49:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes bounce-top {
  10% {
    transform: translateY(-18px);
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }

  65% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }

  82% {
    transform: translateY(-3px);
    animation-timing-function: ease-in;
  }

  93% {
    transform: translateY(-2px);
    animation-timing-function: ease-in;
  }

  0%,
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
